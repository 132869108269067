body {
  min-height: 100%;
}
#root {
  min-height: 100vh;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

#container {
  display: flex;
  flex: 1;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
